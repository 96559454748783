import * as React from "react"

import Layout from "components/layout"
import Headline from "components/general/headline"
import AboutUsComponent from "components/about-us/about-us"

// TailwindUI component: https://tailwindui.com/components/application-ui/lists/grid-lists#component-d6b6c178a9f460d37c542870139e940e
// Gatsby dynamic images: https://www.gatsbyjs.com/plugins/gatsby-plugin-image/#dynamic-images
const AboutUsPage = () => {
  const seo = {
    metaTitle: "Über Uns",
    metaDescription:
      "Kurzer Ausschnitt zur Historie der Faustballer des MTV Vorsfelde",
  }

  return (
    <Layout seo={seo} showSidebar={false}>
      <div className="main-box">
        <Headline
          headline="Über Uns"
          subHeadline="Die 1. Faustballmannschaft des MTV sagt der Polizei Danke. Warum das, was ist passiert?"
        />

        <AboutUsComponent />
      </div>
    </Layout>
  )
}

export default AboutUsPage
