import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const AboutUsComponent = () => {
  return (
    <div className="mt-24">
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-prose text-lg">
          <h1>
            <span className="mt-2 block text-center font-roboto text-3xl leading-8 tracking-tight text-gray-900 dark:text-gray-300 sm:text-4xl">
              Wie alles begann
            </span>
          </h1>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Im Oktober 1979 fand eine Art Betriebssportgemeinschaft aus Zoll,
            Feuerwehr und Polizei beim MTV Vorsfelde eine Heimat in der
            Jedermannsparte.
          </p>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Die daraus entstehende Faustballsparte, es waren ca. 10-15
            erwachsene Männer dabei, mit ihrem 1. Spartenleiter Alfred Jürges,
            heute Ehrenvorsitzender der Faustballer, meldete nach fast
            zweijährigen fleißigen Trainingseinheiten erstmals 1980 eine
            Faustballmannschaft im Turnbezirk Braunschweig in der Bezirksklasse.
          </p>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Hier waren u.a. Mannschaften des TV Jahn Wolfsburg, Post Wolfsburg,
            Eintracht Wolfsburg und der TSV Heiligendorf am Start.
          </p>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Über persönliche Bekanntschaften kam es zu Kontakten mit
            „Artgenossen“ aus Hamburg-Farmsen und Berlin-Tempelhof, sodass die
            Ausrichtung eines eigenen Turniers im Drömlingstadion nicht lange
            auf sich warten ließ.
          </p>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            1983 gelang dann der Aufstieg in die Bezirksliga, wo dann unter
            anderem der MTV Braunschweig, TSV Abbesbüttel, TSV Rüningen, SCE
            Gliesmarode, MTVWolfenbüttel und Salzgitter die Gegner waren.
          </p>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Das Jahr 1991 brachte dann eine große Wende für die Sparte.
          </p>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Während die 1. Mannschaft weiterhin in der Bezirksliga zusammen mit
            einer 2. Formation auf Punktejagd ging, und dann auch noch der
            Aufstieg in die Bezirksoberliga gelang, wurde die Sparte in persona
            mit Frank Kuwert-Behrenz und Dieter Biniek in der Jugendarbeit
            aktiv.
          </p>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Das sollte sich für die kommenden Jahre als Glücksfall und der
            richtige Weg erweisen, denn bis 2023 bildet ein großer Teil der
            damaligen Anfänger noch heute den Stamm des Kaders der aktuellen
            2.Bundesligaformation.
          </p>
        </div>
        <div className="mx-auto mt-8 flex justify-center">
          <StaticImage
            src="../../images/ueber-uns/Scannen0041.jpg"
            className="h-full rounded-lg object-cover"
            alt=""
          />
        </div>
        <div className="mx-auto mt-24 max-w-prose text-lg">
          <h1>
            <span className="mt-2 block text-center font-roboto text-3xl leading-8 tracking-tight text-gray-900 dark:text-gray-300 sm:text-4xl">
              Jugendarbeit
            </span>
          </h1>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Wie geht das, wie die Kid's erreichen, wie die Begeisterung für den
            Sport weitergeben. Am besten über die eigenen Kinder und deren
            Freunde. Also ab in den Kindergarten und die Schule. So konnten
            einige Freunde gewonnen und begeistert werden, die
            „Pampas-Mannschaft“ der Faustballer des MTV Vorsfelde war geboren.
          </p>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Nun gilt es in vielen Training's mit sehr viel Geduld alle bei der
            Stange zu halten und auf Wettkämpfe als Punktspiele oder in
            Turnierform vorzubereiten.
          </p>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Trösten, Schuhe zubinden, ein Eis kaufen, und immer wieder Mut
            zusprechen gehören lange zu den vornehmsten Aufgaben des
            Trainerteam's.
          </p>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Ein Erfolg dieser Bemühungen ist noch heute deutlich, denn aus einem
            guten Dutzend Spielern ist der heutige Kern der 1. Mannschaft immer
            noch dabei und das obwohl mittlerweile fast 25 Jahre ins Land
            gegangen sind.
          </p>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Die offene Aufnahme für Jedermann, die herzliche, familiäre Art
            nicht nur der Sportart sondern auch der Trainer und alles
            Funktionäre schaffte diese gute Atmosphäre und die Arbeit trug
            Früchte. Die Jugendmannschaft konnte sowohl auf
            Bezirksmeisterschaften als auch bei der Landesmeisterschaft mit
            guten Platzierungen aufwarten.
          </p>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Im B-Jugendbereich konnte sogar die Teilnahme an den
            Bundesbestenspielen errungen werden.
          </p>
        </div>
        <div className="mx-auto mt-8 flex justify-center">
          <StaticImage
            src="../../images/ueber-uns/Scannen0029.jpg"
            className="h-full rounded-lg object-cover"
            alt=""
          />
        </div>
        <div className="mx-auto mt-24 max-w-prose text-lg">
          <h1>
            <span className="mt-2 block text-center font-roboto text-3xl leading-8 tracking-tight text-gray-900 dark:text-gray-300 sm:text-4xl">
              Aufstieg
            </span>
          </h1>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Eine bunte Vielfalt an Aktivitäten fand auf und neben dem Platz in
            der sehr lebendigen Sparte statt. Neben z.B. Grünkohlwanderungen,
            Querschneiden zur Weihnachtszeit oder dem ein oder anderen
            Grillabend, wurden die mehrtägigen Beach-Spiele des NTB mit
            Übernachtungen im Zeltlager auf Baltrum besucht.
          </p>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Ein besonderes Highlight war allerdings die Teilnahme am
            Bundesturnfest im Jahr 2005 in Berlin, wo die Mannschaft zusammen
            mit 5000 anderen Aktiven der unterschiedlichsten Sportarten auf dem
            großen Turnier unserem Hobby frönen konnten.
          </p>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Die Jahre 2004-2006 waren dann von außerordentlichem sportlichem
            Erfolg geprägt. Nach und nach wurden die Jugendlichen, mittlerweile
            jungen Erwachsenen in die 1. Mannschaft integriert und reiften dort
            immer mehr zu Leistungsträgern.
          </p>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            2004 wurde ungeschlagen der 1. Platz in der Bezirksoberliga
            erreicht.
          </p>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            2005 trat das Team dann in der Verbandsliga an. Dort präsentierte
            man sich ebenfalls bärenstark, erreichte den 2. Platz der
            Abschlusstabelle und war so nach einem klassischen Durchmarsch für
            die Niedersachsenligasaison 2006 qualifiziert.
          </p>
          <div className="mx-auto mt-8 flex justify-center">
            <StaticImage
              src="../../images/ueber-uns/Scannen0034.jpg"
              className="h-full rounded-lg object-cover"
              alt=""
            />
          </div>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Die Erfolge hatten in der Region aufhorchen lassen und erstmals
            schlossen sich zwei Leistungsträger eines anderen Verein's an.
          </p>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            So gewappnet konnte die Klasse gleich im ersten Jahr mit einem Platz
            im Mittelfeld gehalten werden.
          </p>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Die Saison 2009 brachte dann den Durchbruch, 1. Platz, berechtigt
            zur Teilnahme an den Aufstiegsspielen zur 2. Bundesliga-Nord.
          </p>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Gleich im ersten Anlauf konnte diese Hürde genommen werden. Der MTV
            Vorsfelde war im Bundesligaalltag angekommen.
          </p>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Auch hier wurde das Team schnell zum Leistungsträger und konnte dann
            2012, also schon im dritten Jahr der Zugehörigkeit die Qualifikation
            für die Teilnahme an den Aufstiegspielen zum Oberhaus erreichen. Es
            gelang und in der Sommersaison 2013 konnte der MTV Vorsfelde
            Erstligaluft schnuppern. Leider wurde die Mannschaft durch ein paar
            berufliche Handicap's der Aktiven etwas ausgebremst, was den
            direkten Abstieg zur Folge hatte.
          </p>
          <div className="mx-auto mt-8 flex justify-center">
            <StaticImage
              src="../../images/ueber-uns/Mannschaft_Feld2013.jpg"
              className="h-full rounded-lg object-cover"
              alt=""
            />
          </div>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            In den Folgejahren gab es immer wieder eine relativ hohe Fluktuation
            im Kader, sodass ein erneuter Aufstieg trotz dreimaliger Teilnahme
            an den Aufstiegswettkämpfen in Folge nicht gelang.
          </p>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Dennoch ist die mittlerweile 14-jährige Zugehörigkeit in der 2. Liga
            mehr als ein Achtungserfolg. Der MTV ist eine Institution in der
            Liga, kein anderer Verein präsentiert sich dort durchgängig schon so
            lang und das verdient Anerkennung.
          </p>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Seit der Hallensaison 2015/16 , somit aktuell in der. 9. Saison
            präsentiert sich der MTV Vorsfelde auch in der Halle in der
            2.Bundesliga.
          </p>
        </div>
        <div className="mx-auto mt-8 flex justify-center">
          <video controls>
            <source
              src="https://api.vorsfelde-faustball.de/uploads/MTV_Vorsfelde_30_Jahre_Zur_DM_1dbf12444f.MP4"
              type="video/mp4"
              title="30 Jahre zur Deutschen Meisterschaft"
            />
          </video>
        </div>
        <div className="mx-auto mt-24 max-w-prose text-lg">
          <h1>
            <span className="mt-2 block text-center font-roboto text-3xl leading-8 tracking-tight text-gray-900 dark:text-gray-300 sm:text-4xl">
              Perspektive
            </span>
          </h1>
          <p className="mt-8 text-xl leading-8 text-gray-500">
            Beitrag folgt, wenn das Leben ein weiteres Kapitel geschrieben hat,
            denn der Verfasser ist im gewissen Sinn eben nur ein Chronist.
          </p>
        </div>
      </div>
    </div>
  )
}

export default AboutUsComponent
